import React, { useEffect, useState } from "react";
import "./FacultyMem.css";
import FacultyData from "./FacultyData";
import PageInfo from "../PageInfo/PageInfo";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SaraojBhattarai from "./Saroj_Bhattarai.jpeg";
import Hareram from "./Hareram.jpeg";
import KrishnaDev from "./KrishnaDev.jpeg";
import Ujjwal from "./UjjwalRijal.jpeg";
import JiwanDhamala from "./JiwanDhamala.jpeg";
import axios from "axios";
import { ENDPOINT } from "../GlobalVariables";

const FacultyMem = () => {
  const [facultyMembers, setFacultyMembers] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const { data } = await axios.get(`${ENDPOINT}/api/v1/faculty`);
      try {
        setFacultyMembers(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchItems();
  }, []);

  console.log({ facultyMembers });
  return (
    <>
      <PageInfo title={"Faculty Members"} Icon={PeopleAltOutlinedIcon} />
      <h1 style={{ textAlign: "center", color: "#D90081", paddingTop: "40px" }}>
        <b>Co-ordinators</b>
      </h1>{" "}
      <section className=" text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {" "}
            <FacultyData
              name={"Saroj Bhattarai"}
              image={SaraojBhattarai}
              desc=""
              post={"Co-ordinator"}
            />
            <FacultyData
              name={"Er. Hareram Kamat"}
              image={Hareram}
              desc="B.Sc. CSIT HOD"
              post={"HOD"}
            />
            <FacultyData
              name={"Krishna Dev Bahardar"}
              image={KrishnaDev}
              desc="BIM Co-ordinator"
              post={"Co-ordinator"}
            />
            <FacultyData
              name={"Ujjwal Rijal"}
              image={Ujjwal}
              desc="BCA Co-ordinator"
              post={"Co-ordinator"}
            />
            <FacultyData
              name={"Jiwan Dhamala"}
              image={JiwanDhamala}
              desc="BBS Co-ordinator"
              post={"Co-ordinator"}
            />
          </div>
        </div>
      </section>{" "}
      <h1 style={{ textAlign: "center", color: "#D90081" }}>
        <b>Lecturers</b>
      </h1>{" "}
      <section className="mb-5 text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {facultyMembers?.map((member) => {
              return (
                <FacultyData
                  key={member?._id}
                  name={member?.name}
                  image={member?.pic}
                  post={member?.faculty}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default FacultyMem;
