import React from "react";

const FacultyData = ({ image, name, desc, post }) => {
  return (
    <>
      <div className="p-2 lg:w-1/5 md:w-1/2" style={{ margin: "0px auto" }}>
        <div className="flex flex-col items-center text-center">
          <img
            alt="Faculty member"
            className="rounded-lg w-full mb-3 faculty__members__image__main"
            src={image}
          />
          <div className="w-full">
            <h3 className="text-gray-900 text-lg mb-0">{name}</h3>
            <h2 className="title-font font-medium text-lg mb-0 text-pink-600">
              {post}
            </h2>
            <p className="mb-2 text-sm">{desc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacultyData;
