import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CollectionsIcon from "@mui/icons-material/Collections";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import React, { useState } from "react";
import "./Dashboard.css";
import Notice from "./Notice";
import PostResult from "./PostResult";
import Admission from "./Table/Admission";
import DeleteImage from "./Table/DeleteImage";
import DeleteResult from "./Table/DeleteResult";
import EnentsTable from "./Table/EnentsTable.jsx";
import FacultyMemberTable from "./Table/FacultyMemberTable.jsx";
import NoticeTable from "./Table/NoticeTable";
import Table from "./Table/Table";
import Table2 from "./Table/Table2";
import UpdateEvent from "./UpdateEvent";
import UpdateGallery from "./UpdateGallery";
import Uploadfacultymember from "./UploadFacultymember.jsx";
const Dashboard = () => {
  const [facultyMembers, setFacultyMembers] = useState([]);
  return (
    <>
      <div className="container-fluid">
        {" "}
        <h1 style={{ textAlign: "center", color: "#D90081", padding: "40px" }}>
          <DashboardIcon fontSize="large" style={{ color: "#390081" }} />{" "}
          <b>Dashboard</b>
        </h1>
        <div className="row">
          <div className="col-md-6 col-12 ">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <ChatBubbleIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Faculty members</b>
            </h4>
            <FacultyMemberTable
              facultyMembers={facultyMembers}
              setFacultyMembers={setFacultyMembers}
            />
          </div>
          <div className="col-md-6 col-12 ">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <CollectionsIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Upload faculty member</b>
            </h4>
            <Uploadfacultymember
              facultyMembers={facultyMembers}
              setFacultyMembers={setFacultyMembers}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 ">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <ChatBubbleIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Feedbacks</b>
            </h4>
            <Table />
          </div>
          <div className="col-md-6 col-12 ">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <UnsubscribeIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>NewsLetter</b>
            </h4>
            <Table2 />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-5 col-12">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <CollectionsIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Delete Faculty Image</b>
            </h4>
            <DeleteImage />
          </div>
          <div className="col-md-3 col-12">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <CollectionsIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Upload Faculty Image</b>
            </h4>
            <UpdateGallery />
          </div>
          <div className="col-md-4 col-12">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <CollectionsIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Event Updates</b>
            </h4>
            <UpdateEvent />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <NotificationsActiveIcon
                fontSize="large"
                style={{ color: "#390081" }}
              />{" "}
              <b>Notice Update</b>
            </h4>
            <Notice />
          </div>
          <div className="col-md-4">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <DeleteIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Delete Notice</b>
            </h4>
            <NoticeTable />
          </div>
          <div className="col-md-4">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <PostAddIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Post Result</b>
            </h4>
            <PostResult />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-5">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <DeleteIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>Delete Result</b>
            </h4>
            <DeleteResult />
          </div>
          <div className="col-md-7">
            <h4
              style={{ textAlign: "center", color: "#D90081", padding: "40px" }}
            >
              <EventIcon fontSize="large" style={{ color: "#390081" }} />{" "}
              <b>All Events</b>
            </h4>
            <EnentsTable />
          </div>
        </div>
      </div>
      <div className="container">
        <h4 style={{ textAlign: "center", color: "#D90081", padding: "40px" }}>
          <FormatListNumberedIcon
            fontSize="large"
            style={{ color: "#390081" }}
          />{" "}
          <b>All Online Admission Requests</b>
        </h4>
        <Admission />
      </div>
    </>
  );
};

export default Dashboard;
