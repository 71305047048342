import React, { useMemo } from "react";
import Dashboard from "./Dashboard";
import CryptoJS from "crypto-js";
import { PASSWORD, SECRET_KEY } from "./Dashboard1";
const Panel = () => {
  const isLoggedIn = useMemo(() => {
    let string = JSON.parse(localStorage.getItem("userInfo"));
    if (!string) return false;
    const bytes = CryptoJS.AES.decrypt(string, SECRET_KEY);
    const decoded = bytes.toString(CryptoJS.enc.Utf8);
    return decoded === PASSWORD;
  }, []);
  return (
    <>
      {isLoggedIn ? (
        <Dashboard />
      ) : (
        <h1 style={{ textAlign: "center", margin: "10px 0px", color: "red" }}>
          Not Authenticated
        </h1>
      )}
    </>
  );
};

export default Panel;
