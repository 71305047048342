import React from "react";
import PageInfo from "../PageInfo/PageInfo";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ManagamentInfo from "./ManagamentInfo";

import AcademicManagementTeam from "./AcademicManagementTeam.jpeg";
import Rupesh from "./Rupesh.jpeg";
import Amit from "./Amit.jpeg";
import Dipesh from "./Dipesh.jpeg";
import Tulsi from "./Tulsi.jpeg";
function Management() {
  return (
    <>
      <PageInfo title={"Management Team"} Icon={PeopleAltOutlinedIcon} />

      <section className="text-gray-600 body-font">
        <div className="container px-8 pt-24 pb-10 mx-auto">
          <div className="flex flex-wrap -m-4">
            <ManagamentInfo
              name={"Rupesh Khatiwada"}
              post={"Chairman"}
              image={Rupesh}
              fa="https://www.facebook.com/rupesh.khatiwada.733"
              tw="https://twitter.com/"
              wh="https://www.whatsapp.com/"
            />
            <ManagamentInfo
              name={"Dipesh Bastola"}
              post={"Vice Chairman"}
              image={Dipesh}
              fa="https://www.facebook.com/dpdipesh.bastola"
              tw="https://twitter.com/"
              wh="https://www.whatsapp.com/"
            />{" "}
            <ManagamentInfo
              name={"Amit Dhakal"}
              post={"Campus chief"}
              image={Amit}
              fa="https://www.facebook.com/amit.dhakal.98"
              tw="https://twitter.com/"
              wh="https://www.whatsapp.com/"
            />
            <ManagamentInfo
              name={"Tulsi Khatiwada"}
              post={"Director"}
              image={Tulsi}
              fa="https://www.facebook.com/tulshi.khatiwada.7"
              tw="https://twitter.com/"
              wh="https://www.whatsapp.com/"
            />
          </div>
        </div>
        <h1 style={{ textAlign: "center", color: "#D90081" }}>
          <b>Academic Management Team</b>
        </h1>{" "}
        <div className="px-16">
          <img alt="AcademicManagementTeam" src={AcademicManagementTeam} />
        </div>
      </section>
    </>
  );
}

export default Management;
