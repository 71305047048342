import axios from "axios";
import React, { useEffect, useState } from "react";
import Bba from "../BBA/Bba";
import { ENDPOINT } from "../../GlobalVariables";

const CSIT = () => {
  const [images, setImages] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${ENDPOINT}/api/v1/gallery/faculty/csit`
        );
        setImages(data);
        setloading(false);
      } catch (error) {
        console.log(error.message);
        setloading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <>
        <Bba images={images} courseName="B.Sc. CSIT" loading={loading} />
      </>
    </>
  );
};

export default CSIT;
