import React from "react";
import "./Home.css";
import Amit from "../Management/Amit.jpeg";
const WelcomeMessage = () => {
  return (
    <>
      <div className="container-fluid">
        <section className="mb-2 text-pink-1000">
          <div className="grid md:grid-cols-1 gap-1 text-center">
            <div>
              <div className="block rounded-lg shadow-lg bg-white">
                <div
                  className="overflow-hidden rounded-t-lg h-30"
                  style={{ backgroundColor: "#9d789b" }}
                />
                <div className="w-25 mt-2 overflow-hidden  mx-auto bg-white">
                  <img className="welcome__image " src={Amit} alt="" />
                </div>
                <div className="py-1 px-3">
                  <h4 className="text-2xl font-semibold ">Amit Dhakal</h4>
                  <h5 className="text-sm  mb-4 welcome__principal">
                    The Campus Chief
                  </h5>

                  <hr />
                  <p className="mt-4">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="quote-left"
                      className="w-6 pr-2 inline-block"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                      />
                    </svg>
                    Welcome to Nihareeka College, where we have fostered
                    excellence in education for the past decade. It is my
                    pleasure to introduce you to our institution, where ambition
                    meets opportunity, and dreams become reality. Since our
                    establishment, Nihareeka has distinguished itself as a
                    beacon of academic excellence and innovation. Our dedicated
                    faculty, state-of-the-art facilities, and comprehensive
                    curriculum provide the knowledge, skills, and experiences
                    necessary to thrive in an ever-evolving world. Explore what
                    Nihareeka has to offer and become part of a legacy dedicated
                    to excellence, innovation, and making a difference. We are
                    proud of our 100% internship and job placement rate with
                    prestigious IT companies like F1 Soft, e-Sewa, and
                    DiyaloTech. With a state-of-the-art learning environment, we
                    offer a wide range of TU-affiliated programs in IT and
                    management, such as B.Sc. CSIT, BCA, BIM, BHM, BBS, and MBS.
                    Join us and be part of a thriving academic community
                    dedicated to your success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WelcomeMessage;
