import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../GlobalVariables";
import Loader from "../../Loader";

const FacultyMemberTable = ({ facultyMembers, setFacultyMembers }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      const { data } = await axios.get(`${ENDPOINT}/api/v1/faculty`);
      try {
        setLoading(true);
        setFacultyMembers(data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchItems();
  }, []);

  const deleteFaculty = async (id) => {
    try {
      const { data } = await axios.delete(`${ENDPOINT}/api/v1/faculty/${id}`);
      setFacultyMembers(facultyMembers.filter((f) => f._id !== id));
      toast.success("Image Deleted Successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table className="table main__secction1" style={{ overflowX: "auto" }}>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Loader />}
            {facultyMembers
              ? facultyMembers.length === 0 && (
                  <h6 style={{ marginTop: "10px" }}>
                    No Faculty member Images
                  </h6>
                )
              : null}
            {facultyMembers.map((value, index) => {
              return (
                <tr key={value._id}>
                  <td>{value.name}</td> <th scope="row">{value.faculty}</th>
                  <td>
                    <div className="bg-cyan-300">
                      <img
                        src={value.pic}
                        className="object-scale-down h-10 w-auto"
                        alt="faculty"
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <button>
                      {" "}
                      <Tooltip title="Delete">
                        <DeleteIcon
                          fontSize="small"
                          style={{ color: "#d11a2a", marginLeft: "15px" }}
                          onClick={() => deleteFaculty(value._id)}
                        />
                      </Tooltip>
                    </button>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FacultyMemberTable;
