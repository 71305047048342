import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect } from "react";
import Modal1 from "react-modal";
import "./Modal.css";
import PopupImage from "./PopupImage.jpeg";

const customStyles = {
  content: {
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    marginTop: "20px",
    zIndex: "1",
  },
};

const Modal = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    setIsOpen(true);
  }, []);
  return (
    <Modal1
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      // contentLabel="Example Modal"
    >
      <div onClick={closeModal} className="modal__close__main__div">
        <h6 className="mb-0"> Admission Open!</h6>{" "}
        <ClearIcon onClick={closeModal} className="modal__close__main" />
      </div>

      <img
        alt="Popup"
        src={PopupImage}
        style={{ width: "100%", height: "300px" }}
      />
      <div onClick={closeModal} className="text-center mt-2 cursor-pointer">
        <h6 className="mb-0 text-danger mt-1"> Click here to close</h6>{" "}
      </div>
    </Modal1>
  );
};

export default Modal;
