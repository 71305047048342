import React from "react";
import "./Testimonials.css";
import Neha from "./Neha.jpeg";
import Esewa from "./Esewa.png";
const Testimonials = () => {
  return (
    <>
      <div className="testimonials-section">
        <input
          type="radio"
          name="slider"
          title="slide1"
          defaultChecked="checked"
          className="slider__nav"
        />
        <input
          type="radio"
          name="slider"
          title="slide2"
          className="slider__nav"
        />
        <input
          type="radio"
          name="slider"
          title="slide3"
          className="slider__nav"
        />
        <input
          type="radio"
          name="slider"
          title="slide4"
          className="slider__nav"
        />
        <input
          type="radio"
          name="slider"
          title="slide5"
          className="slider__nav"
        />
        <div className="slider__inner">
          <div className="slider__contents">
            <img
              src={Neha}
              alt=""
              srcset=""
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <quote>”</quote>
            <p className="slider__txt">
              Nihareeka College provided me with the skills and confidence to
              excel in my career. The faculty is supportive, and the curriculum
              is truly comprehensive.
            </p>
            <h2 className="slider__caption">Neha Koirala | Student</h2>
          </div>{" "}
          <div className="slider__contents">
            <img
              src={Esewa}
              alt=""
              srcset=""
              style={{ width: "150px", height: "150px", borderRadius: "50%" }}
            />
            <quote>”</quote>
            <p className="slider__txt">
              The graduates we’ve hired from Nihareeka College are well-prepared
              and demonstrate a strong work ethic. The college clearly
              emphasizes practical skills and innovation.
            </p>
            <h2 className="slider__caption">Esewa</h2>{" "}
          </div>{" "}
          <div className="slider__contents">
            <img
              src="https://res.cloudinary.com/mechi-pharma123/image/upload/v1654320183/Faculty%20Members/WhatsApp_Image_2022-06-04_at_11.07.52_AM_kyi6da.jpg"
              alt=""
              srcset=""
              style={{ width: "150px", height: "150px", borderRadius: "50%" }}
            />
            <quote>”</quote>
            <p className="slider__txt">
              Being a faculty member at Nihareeka College is incredibly
              fulfilling. The institution's commitment to academic excellence
              and innovation allows me to inspire and mentor students to reach
              their full potential.
            </p>
            <h2 className="slider__caption">
              Krishna Dev Bahardar | BIM Co-ordinator
            </h2>{" "}
          </div>
          <div className="slider__contents">
            <img
              src="https://res.cloudinary.com/mechi-pharma123/image/upload/v1655030167/Faculty%20Members/aman_sffjvr.jpg"
              alt=""
              srcset=""
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <quote>”</quote>
            <p className="slider__txt">
              Nihareeka College has been a transformative experience for me. The
              hands-on learning and real-world applications have truly prepared
              me for the challenges in the IT industry.
            </p>
            <h2 className="slider__caption">Aman Rajbansi | Student</h2>{" "}
          </div>{" "}
          <div className="slider__contents">
            <img
              src="https://res.cloudinary.com/mechi-pharma123/image/upload/v1654319881/Faculty%20Members/WhatsApp_Image_2022-06-04_at_11.02.35_AM_pgkvda.jpg"
              alt=""
              srcset=""
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <quote>”</quote>
            <p className="slider__txt">
              Teaching at Nihareeka College has been a rewarding experience. The
              students are eager to learn, and the college fosters an
              environment of creativity and collaboration.
            </p>
            <h2 className="slider__caption">Er. Hareram Kamat | HOD</h2>{" "}
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
